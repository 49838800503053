var script = document.createElement('script');
script.src =
    'https://maps.googleapis.com/maps/api/js?libraries=places,visualization&key=AIzaSyAWAOoYjKnIZEx8sAWJzY18DM_vaioWYfA&v=weekly&callback=Window.initMap';
script.async=true;

document.head.appendChild(script);

let map=null;
let directionsService;
let directionsRenderer;
let markers = [];
let myPos = { lat: 17.908658, lng: -62.835965 };
let defaultPos = { lat: 17.908658, lng: -62.835965 };
let customerPos= myPos;

export function initMap(pLatLng = null, front = false ) {
    var myLatLng = { lat: 17.904444, lng: -62.84361000000001 };
    directionsRenderer = new google.maps.DirectionsRenderer();
    directionsService = new google.maps.DirectionsService();

    if(front == true){
        var mapType = 'roadmap'
        myLatLng = { lat: 17.901996040205898, lng: -62.82752669715881 };
    } else {
        var mapType = 'hybrid'
    }


    map = new google.maps.Map(document.getElementById("map"), {
        zoom: 13,
        center: myLatLng,
        mapTypeId: mapType
    });

    if(front == false) {
        refreshMap(Window.GMAPCoord)
    }
}

function deleteMarkers() {
    clearMarkers();
    markers = [];
}

// Sets the map on all markers in the array.
function setMapOnAll(map) {
    for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(map);
    }
}

// Removes the markers from the map, but keeps them in the array.
function clearMarkers() {
    setMapOnAll(null);
}

export function refreshMap(pLatLng = null){
    //alert('coucou');
    if(map==null){
        initMap();
    }

    if(pLatLng==null){

        pLatLng = defaultPos;
    }
    clearMarkers();
    directionsRenderer.setMap(null);
    //calculateAndDisplayRoute(null);

    addMarker(pLatLng);
    map.setCenter(pLatLng);


}
export function displayRoute(pLatLng, pCustomer){

    clearMarkers();

    addMarker(pLatLng);
    map.setCenter(pLatLng);

    geolocateMe(map);
    customerPos = pLatLng;

    calculateAndDisplayRoute(directionsService,directionsRenderer);
    document.getElementById("modalGoogleMapsTitle").innerHTML =
        "Géolocalisation <strong>"+pCustomer.name+
        " à "+pCustomer.district+
        "</strong> <br> "+pCustomer.commentForDelivery;

}

export function addMarker(pLatLng, label='', title = "", draggable = true){

    const marker =    new google.maps.Marker({
        position: pLatLng,
        map,
        label: label,
        title: title,
        draggable: draggable
    });

    if(draggable == true){
        $("#gpsData").val(marker.getPosition().lat()+','+marker.getPosition().lng());

        marker.addListener("dragend", () => {
            $("#gpsData").val(marker.getPosition().lat()+','+marker.getPosition().lng());
        });
    } else {
        var contentString1 = "<h3 class='m-0'>"+ title +"</h3>"
        var infowindow = new google.maps.InfoWindow({content: contentString1});

        marker.addListener("click", () => {
            infowindow.open(map, marker);
        });
    }

    markers.push(marker);
}

let infoWindow;

export function geolocateMe(map){
    infoWindow = new google.maps.InfoWindow;
    // Try HTML5 geolocation.
    clearMarkers();
    if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(function(position) {
        var lat = position.coords.latitude;
        var lng = position.coords.longitude;
        if (lat > 17.87 && lat < 17.95) {
            // document.getElementById("us2-lat").value = position.coords.latitude;
        } else {
            lat = 17.904444;
        }
        if (lng > -62.9 && lng < -62.77){
        } else {
            lng = -62.84361000000001;
        }
        myPos = {
            lat: lat,
            lng: lng
        };

        map.setCenter(myPos);
        addMarker(myPos)

    }, function() {
        clearMarkers();
            addMarker(defaultPos)

            });
    } else {
    // Browser doesn't support Geolocation
        handleLocationError(false, infoWindow, map.getCenter());
        addMarker(defaultPos)
    }
}
function handleLocationError(browserHasGeolocation, infoWindow, pos) {
    infoWindow.setPosition(pos);
    infoWindow.setContent(browserHasGeolocation ?
    'Error: The Geolocation service failed.' :
    'Error: Your browser doesn\'t support geolocation.');
    infoWindow.open(map);
}

export function calculateAndDisplayRoute(directionsService, directionsRenderer) {
    directionsRenderer.setMap(map);
    directionsService.route(
        {
            origin: myPos,
            destination: customerPos,
            travelMode: google.maps.TravelMode.DRIVING,
        },
        (response, status) => {
            if (status === "OK") {
                directionsRenderer.setDirections(response);
            } else {
                window.alert("Directions request failed due to " + status);
            }
        }
    );
}
