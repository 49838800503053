/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import './styles/app.css';


//import $ from "jquery";
import SlimSelect from 'slim-select'

import bootstrap from 'bootstrap/dist/js/bootstrap'

// start the Stimulus application
import './bootstrap';
import 'htmx.org';
window.htmx = require('htmx.org')

import {addMarker, displayRoute, geolocateMe, initMap, refreshMap} from "./my-helper";

Window.initSlimSelectByClassName = initSlimSelectByClassName
Window.initMap = initMap;
Window.addMarker = addMarker;
Window.geolocateMe = geolocateMe;
Window.displayRoute = displayRoute;
Window.refreshMap = refreshMap;
Window.showCardFooters = showCardFooters;
Window.showDiv = showDiv;
Window.reCaptcha = reCaptcha;

export function initSlimSelectByClassName (className){
    new SlimSelect({
        select: className,
        placeholder: 'Sélectionner',
        searchText: 'Recherche'
    })
}

function showCardFooters() {
    $(".card-footer").removeClass("d-none");
}
function showDiv(id) {
    $("#"+id).removeClass("d-none");
}

function reCaptcha() {
    const siteKey = '6LcKfT8kAAAAACI6eGaRA456aBETl5SvAv1unieM';

//either on page load
    grecaptcha.ready(function() {
        grecaptcha.execute(siteKey, {
            action: 'contact'
        }).then(function(token) {
            //the token will be sent on form submit
            $('[name="captcha"]').val(token);
            //keep in mind that token expires in 120 seconds so it's better to add setTimeout.
        });
    });
}